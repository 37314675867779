// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    increment,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import {getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyASkx4euJosRXyuDCzpatxXQjANCDusAqM",
    authDomain: "technixo-got-talent-voting.firebaseapp.com",
    projectId: "technixo-got-talent-voting",
    storageBucket: "technixo-got-talent-voting.appspot.com",
    messagingSenderId: "340867153935",
    appId: "1:340867153935:web:755bdf00e452946d3d237a"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth()

const configRef = doc(db, "config", "config");

export function subscribeConfig(onConfig){
    // subscribe to config
    return onSnapshot(configRef, (doc) => {
        console.log("Current config data: ", doc.data());
        onConfig(doc.data());
    })
}

export function subscribeOnline(onOnline) {
    return onSnapshot(collection(db, "online"), (snapshot) => {
        onOnline(snapshot.docs.map(doc => doc.data()));
    })
}

export async function getTeam(id){
    const teamRef = doc(db, "team", `${id}`);
    const docSnap = await getDoc(teamRef)
    console.log(docSnap)
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data()
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
}

export function finish(){
    return updateDoc(configRef, {
        is_finished: true
    })
}

export function setStarted(){
    return updateDoc(configRef, {is_started: true, team: 1, update_at: serverTimestamp()})
}

export function nextTeam(){
    return updateDoc(configRef, {team: increment(1), update_at: serverTimestamp()})
}

export async function countOnline(){
    // 5 minutes recently
    const _query = query(collection(db, "online"), where("update_at", ">", new Date(new Date().getTime() - 5 * 60 * 1000)));
    const querySnapshot = await getDocs(_query);
    return querySnapshot.size;
}

export async function countTotalUser(){
    const querySnapshot = await getDocs(query(collection(db, "user_email")));
    return querySnapshot.size;
}

function _formatEmail(email){
    const formattedEmail = email.replace("@", "_").replace(".", "_");

    return formattedEmail
}

function formattedEmail(){
    const user = auth.currentUser || {email: ''};
    return _formatEmail(user.email);
}

export async function countNotAbleToVote(teamId){
    // 5 minutes recently
    let _query = query(collection(db, "user_email"), where("update_at", ">", new Date(new Date().getTime() - 5 * 60 * 1000)));
    _query = query(_query, where("team_id", "==", teamId));
    const querySnapshot = await getDocs(_query);
    return querySnapshot.size;
}

export async function isVoteAble(teamId){
    try{
        let {email, uid} = auth.currentUser || {}
        if(teamId === 6){
            email = formatQCEmail(email)
        }
        const isBlackListed = async (teamId, email)  => {
            try{
                let _query = query(collection(db, "user_email"), where("team_id", "==", teamId))
                _query = query(_query, where("email", "==", email));
                const docSnap = await getDocs(_query);
                return docSnap.size > 0;
            }catch (e) {
                console.log("isBlackListed error", e)
                return false;
            }
        }

        const isVotedByTeam = async (teamId, uid) => {
            const docRef = doc(db, "team", `${teamId}`, "votes", uid);
            const docSnap = await getDoc(docRef);
            return docSnap.exists();
        }

        const [isBlackList, isVoted] = await Promise.all([
            isBlackListed(teamId, email),
            isVotedByTeam(teamId, uid)
        ])
        console.log("isVoted", isVoted, isBlackList, isTechnixoEmail(email))
        return !isVoted && !isBlackList && isTechnixoEmail(email)
    }catch (e){

        console.log("isVoteAble failed",e)
    }
}

async function calculatePointPerVote(teamId){
    // pointPerVote = 100 / (totalOnline - notAbleToVote)
    const [online, notAbleToVote] = await Promise.all([countOnline(), countNotAbleToVote(teamId)])
    console.log("online, notAbleToVote >>", online, notAbleToVote)
    const point = 100 / (online - notAbleToVote)
    console.log("point", point)
    return point
}

export async function voteFor(teamId, myPoint){
    const point = await calculatePointPerVote(teamId)
    const teamRef = doc(db, "team", `${teamId}`);
    const {uid} = auth.currentUser
    const voteRef = doc(db, "team", `${teamId}`, "votes", `${uid}`);
    const finalPoint = point * myPoint / 10
    return Promise.all([
        updateDoc(teamRef, {point: increment(finalPoint), update_at: serverTimestamp()}),
        setDoc(voteRef, {point: finalPoint, update_at: serverTimestamp()})
    ])
}

export function loginWithGoogleSignIn() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
}

export function logout(){
    const auth = getAuth()
    return signOut(auth)
}

export async function getTeamResult() {
    const colRef = collection(db, "team");
    return await getDocs(query(colRef));
}

async function updateUserEmail(email){
    if(isQcTeam(email)){
        const _email = email + '_QC'
        await updateDoc(doc(db, "user_email", `${_formatEmail(_email)}`), {update_at: serverTimestamp()})
    }
    return updateDoc(doc(db, "user_email", `${_formatEmail(email)}`), {update_at: serverTimestamp()})
}

function newUserLogin(uid, email){
    const colRef = collection(db, "online");
    if(!isTechnixoEmail(email)){
        return;
    }
    return Promise.all([
        setDoc(doc(colRef, `${uid}`), {update_at: serverTimestamp()}),
        updateUserEmail(email)
    ])
}

function formatQCEmail(email){
    return email + '_QC'
}

function isQcTeam(email){
    const emails = `namlee97@technixo.com
anhvt@technixo.com
maictn@technixo.com
duongxn@technixo.com
linhh129198@technixo.com
vudx@technixo.com
ninhht@technixo.com
hoabt@technixo.com
phuongbb@technixo.com`
    return emails.split("\n").includes(email)
}

export function isTechnixoEmail(email){
    return email.includes("@technixo.com")
}
export function isLoggedWithTechnixoEmail(){
    const {email} = auth.currentUser || {}
    if(!email) return false
    return isTechnixoEmail(email)
}

export async function insertTeam(email, team){
   await setDoc(doc(db, "user_email", `${_formatEmail(email)}`), {email, team_id: team, update_at: serverTimestamp()})
}


onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setInterval(() => {
            return newUserLogin(uid, user.email)
        }, 60*1000)
        return newUserLogin(uid, user.email)
    } else {
        // User is signed out
        // ...
    }
});


export {auth}
