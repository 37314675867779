import {Typography} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";

export default function QRCode({isEndUser = false}){
    return (<Container maxWidth="sm" style={{ marginTop: '100px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
            {isEndUser ? "Share QR Code for others" : "Please scan QR code below to vote"}
        </Typography>
        <br />
        <img src={'https://storage.googleapis.com/file_service_qc/got-talent/qr-technixo-vote.png'} width={'300px'}/>
    </Container>)
}
