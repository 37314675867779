import * as React from "react";
import {styled, Typography} from "@mui/material";
import {useEffect} from "react";
import {countOnline, countTotalUser, subscribeOnline} from "../firebase";
const Item = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Online() {
    const [online, setOnline] = React.useState(0);
    const [totalUser, setTotalUser] = React.useState(0);
    const getOnline = () => {
        countOnline().then((res) => {
            console.log("online", res)
            setOnline(res);
        });
    }
    useEffect(() => {
        getOnline()
        countTotalUser().then(r => {
            setTotalUser(r)
        })
        subscribeOnline(getOnline);
    }, [])
    return (
        <Item variant={"body1"}>Online: {online}/{totalUser}</Item>
    )
}
