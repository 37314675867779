import * as React from 'react';
import Container from "@mui/material/Container";
import {Button, Grid, Typography} from "@mui/material";
import {ConfigContext} from "../context";
import {finish, insertUser, nextTeam, setStarted} from "../firebase";
import TeamData from "../components/TeamData";
import {useEffect, useState} from "react";
import {VOTING_TIME} from "../constant";
import Chart from "./Chart";


export function Admin(){
    const config = React.useContext(ConfigContext);
    const getDiffSeconds = () => new Date((config.update_at || {}).seconds*1000 + VOTING_TIME).getTime() - new Date().getTime();
    const [diffTime, setDiffTime] = useState(getDiffSeconds())
    useEffect(() => {
        if(diffTime < 0) return
        setTimeout(() => {
            setDiffTime(0)
        }, diffTime)
        // return clearTimeout(timeout)
    }, [diffTime])
    useEffect(() => {
        setDiffTime(getDiffSeconds())
    }, [(config.update_at || {}).seconds])
    if(config.is_finished){
        return <Chart />
    }
    const ControlButtons = () => {
        if(config.is_started){
            return (
                <>
                    <TeamData />
                    <Typography>Tiết mục {config.team}/{config.maxTeam}</Typography>
                    {
                        config.team === config.maxTeam ? (
                            <Button disabled={diffTime > 0} variant="contained" onClick={() => finish()}>Finish</Button>
                        ) : (<Button disabled={diffTime > 0} variant="contained" onClick={() => nextTeam()}>Next Team</Button>)
                    }
                </>
            )
        }
        return (
            <Button variant={"outlined"} onClick={() => {
                return setStarted().then(() => {
                    console.log("Success")
                })
            }}>Start</Button>
        )
    }
    return (
        <React.Fragment>
            <Container>
                <Grid alignSelf="center">
                    <ControlButtons />
                </Grid>
            </Container>
        </React.Fragment>
    )
}
