import {useContext, useEffect, useState} from "react";
import {ConfigContext} from "../context";
import {getTeam} from "../firebase";
import {Box} from "@mui/material";
import {Image, Login} from "@mui/icons-material";
import {VOTING_TIME} from "../constant";

export default function TeamData() {
    const [loading, setLoading] = useState(false);
    const [teamData, setTeamData] = useState({})
    const config = useContext(ConfigContext)
    useEffect(() => {
        setLoading(true)
        getTeam(config.team).then((data) => {
            setTeamData(data)
            setLoading(false)
        }).catch((reason) => {
            alert("Something went wrong.")
            console.log(reason)
            setLoading(false)
        })
    }, [config.team])

    const Countdown = () => {
        const getSeconds = () => {
            const update_at = (config.update_at || {}).seconds*1000
            const target = new Date(update_at + VOTING_TIME)
            const now = new Date()
            const diff = -now.getTime() + target.getTime()
            return Math.floor(diff / 1000)
        }
        const [seconds, setSeconds] = useState(getSeconds())
        // countdown 30 seconds from updated_at
        useEffect(() => {
            const interval = setInterval(() => {
                setSeconds(getSeconds())
                if(seconds <= 0){
                    clearInterval(interval)
                }
            }, 1000);
            return () => clearInterval(interval);
        }, []);
        if(seconds <= 0){
            return 'Hết giờ!!!'
        }

        return `${seconds || 30} giây`
    }

    if(!teamData){
        return <h1>Something went wrong.</h1>
    }

    return (
        <Box>
            {
                loading ? (
                    <h1>Loading</h1>
                ) : (
                    <>
                        <h1>Team {teamData.name}</h1>
                        <p>Tiết mục: {teamData.act_name}</p>
                        <img width={"100%"} style={{ maxWidth: '600px' }} src={teamData.avatar} />
                        <p>{teamData.message}</p>
                        <p>Vote trong: <Countdown /></p>
                    </>
                )
            }
        </Box>
    )
}
