import * as React from 'react';
import Container from '@mui/material/Container';
import {Button, Grid, Paper, Rating, styled, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {ConfigContext} from "../context";
import TeamData from "../components/TeamData";
import QRCode from "../components/QRCode";


const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});


export default function Home() {
  const {is_started: isStarted, is_finished: isFinished} = useContext(ConfigContext)

    if(isFinished){
        return (
            <Container maxWidth="sm" style={{ marginTop: '100px' }}>
                <Typography variant="h1" component={"h1"}>Vote Finished. Thank you!</Typography>
            </Container>
        )
    }

  if(!isStarted){
    return (
        <QRCode  />
    )
  }
  return (
    <React.Fragment>
      <Container>
        <Grid>
          <TeamData />
        </Grid>
      </Container>
    </React.Fragment>
  );
}
