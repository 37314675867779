import * as React from 'react';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import {Button, Grid, Paper, Rating, Snackbar, styled, Typography} from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {useContext, useEffect, useState} from "react";
import {ConfigContext} from "../context";
import TeamData from "../components/TeamData";
import {auth, isLoggedWithTechnixoEmail, isVoteAble, loginWithGoogleSignIn, logout, voteFor} from "../firebase";
import {VOTING_TIME} from "../constant";
import Chart from "./Chart";
import Online from "../components/Online";
import {onAuthStateChanged} from "firebase/auth";
import {Alert} from "@mui/lab";
import QRCode from "../components/QRCode";


const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});

function Vote() {
    const config = useContext(ConfigContext)
    const {team} = config
    const [value, setValue] = useState(0);
    const [isDone, setIsDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAbleToVote, setIsAbleToVote] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const getDiffSeconds = () => new Date((config.update_at || {}).seconds*1000 + VOTING_TIME - 2000).getTime() - new Date().getTime(); // miniseconds
    // const [diffTime, setDiffTime] = useState(getDiffSeconds())
    useEffect(() => {
        // setDiffTime(getDiffSeconds())
        setValue(0)
        setIsDone(false)
        const diffTimeRaw = getDiffSeconds()
        isVoteAble(team).then((isAble) => {
            if(diffTimeRaw > 0)
                setIsAbleToVote(isAble)
            else{
                setIsAbleToVote(false)
            }
        })

        if(diffTimeRaw < 0) {
            setIsAbleToVote(false)
            setValue(0)
            return
        }
        setTimeout(() => {
            if(value > 0)
                onConfirm()
            setTimeout(() => {
                setIsAbleToVote(false)
                setValue(0)
            }, 2000)
        }, diffTimeRaw)

    }, [team])

    const onConfirm = () => {
        if(value <= 0) return
        setLoading(true)
        return voteFor(team, value).then(() => {
            setIsDone(true)
            setValue(0)
        }).catch(() => {
            setOpenSnackBar(true)
        }).finally(() => {setLoading(false)})
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };


    const DoneBtn = () =>  {
        return value > 0 ? (<LoadingButton loading={loading} variant="contained" onClick={() => onConfirm()}>Confirm</LoadingButton>) : (<></>)
    }
    if(isAbleToVote === null){
        return (<Typography>Loading...</Typography>)
    }
    if(!isAbleToVote){
        return (<Typography>You cannot vote.</Typography>)
    }
    if(isDone){
        return (<Typography>Vote done!!</Typography>)
    }
    return (<>
            <Typography component="legend">Let's vote</Typography>
            <StyledRating
                name="customized-color"
                defaultValue={0}
                precision={0.5}
                max={10}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                icon={<FavoriteIcon fontSize="inherit" />}
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
            />
            <DoneBtn />
            <Snackbar
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={handleClose}
            ><Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Some things went wrong. Vote is not counted.</Alert></Snackbar>
        </>
    )
}

function Authenticated({isStarted = false, isFinished = false}){
    const name = (auth.currentUser || {}).displayName || ''
    if(!isLoggedWithTechnixoEmail()){
        return (
              <>
                  <Typography variant={"h1"}>Please login with @technixo.com email</Typography>
                  <Button onClick={() =>{
                      logout().catch(() => {
                          alert("An error occurred. Please try again")
                      })
                  }}>Logout</Button>
              </>
        )
    }
    if(isFinished){
        return (
              <Typography>Vote Finished. Thank you.</Typography>
        )
    }
    if(isStarted){
        return (
            <>
                <TeamData />
                <Vote />
            </>
        )
    }
    return (
        <>
            <Typography>Login as {name}. But the vote is not started yet. Please wait for 1,000 years.</Typography>
            <Typography>Vui lòng giữ trật tự và nghe theo chỉ dẫn của MC. Thank you {"<3"}</Typography>
            <QRCode isEndUser={true} />
        </>
    )
}

export default function Home() {
    const {is_started: isStarted, is_finished: isFinished} = useContext(ConfigContext)
    const [isLoggedIn, setIsLoggedIn] = useState(auth.currentUser !== null)
    onAuthStateChanged(auth, (user) => {
        if (user) {
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
        }
    })
    if(!isLoggedIn){
        return (
            <Container maxWidth="sm">
                <Typography variant="h4" component="h1" gutterBottom>
                    Please login
                </Typography>
                <Button onClick={
                    () => {
                        loginWithGoogleSignIn().then(
                            (result) => {
                                console.log("result", result)
                                setIsLoggedIn(true)
                            }
                        )
                    }

                }>Login</Button>
            </Container>
        )
    }
    return (
        <React.Fragment>
            <Container>
                <Grid>
                   <Online />
                    <Authenticated isStarted={isStarted} isFinished={isFinished}/>
                </Grid>
                {/*<Box sx={{ bgcolor: '#cfe8fc', height: '100vh' }} />*/}
            </Container>
        </React.Fragment>
    );
}
