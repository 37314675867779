import './App.css';
import "./firebase"
import Home from "./pages/Home";
import Viewer from "./pages/Viewer";
import {ConfigContext, VotingTimeCountdown} from "./context"
import {useEffect, useState} from "react";
import {subscribeConfig} from "./firebase";
import {
    BrowserRouter,
    Route,
    Link, Routes
} from "react-router-dom";
import {Admin} from "./pages/Admin";
import Chart from "./pages/Chart";

function App() {
    const [config, setConfig] = useState({is_started: false, team: 0})
    useEffect(() => {
        return subscribeConfig((config) => {
            setConfig(config)
        })
    }, [])
  return (
        <div className="App">
        <ConfigContext.Provider value={config}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/chart" element={<Chart />} />
                      <Route path="/viewer" element={<Viewer />} />
                    </Routes>
                </BrowserRouter>
        </ConfigContext.Provider>
        </div>
  );
}

export default App;
