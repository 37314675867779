import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {ConfigContext} from "../context";
import TeamData from "../components/TeamData";
import {Grid} from "@mui/material";
import Container from "@mui/material/Container";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {TIME_OUT_DRAWING} from "../constant";
import {getTeamResult} from "../firebase";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    responsive: true,
    dataset: {
        active: false
    },
    aspectRatio: 1.4,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: 'Kết quả quay sổ xố',
        },
    },
};

function ShowingChart() {
    const [loading, setLoading] = useState(true);
    const [teamResults, setTeamResults] = useState([]);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, TIME_OUT_DRAWING);
    }, []);

    useEffect(() => {
        getTeamResult().then(teamResults => {
            setTeamResults(teamResults.docs.map(a=>a.data()));
        })

    }, [])
    const labels = [];
    const chartData = []
    teamResults.forEach(team => {
        labels.push(team.name);
        chartData.push(team.point)
    })

    if(loading){
        return <div>Loading...</div>
    }

    const data = {
        labels,
        datasets: [
            {
                label: 'Point',
                data: chartData,
                backgroundColor: 'rgba(38,159,92,0.6)',
            },
        ],
    };

    return (
        <Bar options={options} data={data} />
    );
}

function Entrypoint(){
    const config = useContext(ConfigContext)
    if(config.is_finished){
        return <ShowingChart />
    }
    return <TeamData />
}

export default function Chart() {

    return (
        <>
            <React.Fragment>
                <Container>
                    <Grid>
                       <Entrypoint />
                    </Grid>
                </Container>
            </React.Fragment>
        </>
    )
}
